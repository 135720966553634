<template>
  <b-row>
    <b-col cols="12">
      <div style="text-align: center">
        <div class="comercial-title">{{ $t("COMERCIAL.ABAVADU") }}</div>
      </div>
    </b-col>
    <b-col cols="12"
      ><span
        class="display:'none'"
        :onclick="openInNewTab('https://www.vadu.com.br')"
      >
        .
      </span></b-col
    >
  </b-row>
</template>

<script>
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  name: "Index",
  data() {
    return {};
  },
  methods: {
    openInNewTab(url) {
      window.open(url, "_blank");
    },
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.VADU") },
    ]);
    this.openInNewTab("https://www.vadu.com.br");
  },
};
</script>

<style scoped>
.comercial-title {
  font-size: 18px;
  font-weight: 600;
  color: #343a40;
  margin: auto;
}
</style>
